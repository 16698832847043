import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query GET_GAL_IMG {
      wpPage(databaseId: { eq: 38 }) {
        homeACF {
          gallery {
            altText
            caption
            id
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2560)
              }
            }
          }
        }
      }
    }
  `)

  const [modal, setModal] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [slideSide, setSlideSide] = useState(null)

  const openModal = index => {
    setModal(true)
    setCurrentIndex(index)
  }

  const closeModal = () => {
    setModal(false)
  }

  const previous = () => {
    setSlideSide("left")
    setCurrentIndex(
      currentIndex > 0
        ? currentIndex - 1
        : data.wpPage.homeACF.gallery.length - 1
    )
  }

  const next = () => {
    setSlideSide("right")
    setCurrentIndex(
      currentIndex < data.wpPage.homeACF.gallery.length - 1
        ? currentIndex + 1
        : 0
    )
  }

  const activeImage = data.wpPage.homeACF.gallery[currentIndex]
  const activeImageData = getImage(activeImage.localFile)

  return (
    <div>
      <div className="row" id="gallery">
        {data.wpPage.homeACF.gallery.map((image, i) => {
          const imageData = getImage(image.localFile)
          return (
            <div
              key={i}
              className="col-12 col-sm-6 col-lg-3"
              role="img"
              onClick={() => openModal(i)}
            >
              <div
                className="gallery-img"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <GatsbyImage image={imageData} alt="image.altText" />
              </div>
            </div>
          )
        })}
      </div>

      {modal && (
        <div className="portfolio__modal">
          <div className="modal__overlay" onClick={closeModal} />
          <div id="modClose" onClick={closeModal} role="button" tabIndex={0}>
            CLOSE
          </div>
          <div
            className={`modal__content modal__content--slide-${slideSide}`}
            onAnimationEnd={() => setSlideSide(null)}
          >
            <div className="col-12 mod-col">
              <button className="modal__previous" onClick={previous} />

              <button className="modal__next" onClick={next} />
              <div style={{ maxWidth: "100%" }}>
                <GatsbyImage
                  image={activeImageData}
                  alt={activeImage.altText}
                />

                <div className="gal-cap">
                  <h4>{activeImage.title}</h4>
                  <p>{parse(activeImage.caption)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Gallery
