import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SectionBlogUpdates from "../components/section-updates"
import HomeMap from "../components/home-map"
import Gallery from "../components/gallery"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="blue-container">
      <div className="container" id="banner-container">
        <div className="row">
          <div className="col-12 col-md-8">
            <StaticImage
              loading="lazy"
              placeholder="blurred"
              src="../images/Group-1306.png"
              alt="Illustration of people sitting on larger-than-life books and talking."
            />
          </div>
          <div class="col-12 col-md-4">
            <span id="main-text">
              <h3>Explore Our Extensive Collections</h3>
              <p>
                Our Libraries are a member of OCLC, an online bibliographic
                utility used by thousands of libraries world-wide to share
                cataloging records and participate in interlibrary loan. The CFI
                Libraries are also a member of NYLINK, a consortium that
                provides resources and collaboration amongst New York State
                libraries.
              </p>
            </span>
            <a
              href="https://cfilibraries.knowallmatrix.online/"
              class="btn orange-button"
            >
              Search the Catalog
            </a>
          </div>
        </div>
      </div>
      <div className="container" id="map-container">
        <HomeMap />
      </div>

      <div className="container" id="blog-container">
        <SectionBlogUpdates />
        <br />
        <div className="btn-row row">
          <a
            href="https://centerforinquiry.org/blog/category/access-points/"
            class="btn orange-button"
          >
            Read More About The Library
          </a>
        </div>
      </div>
      <div className="container" id="gallery-container">
        <div className="home-header">
          <h2>
            <strong>Our Collections</strong>
          </h2>
          <hr></hr>
        </div>

        <Gallery />
      </div>
    </div>
  </Layout>
)

export default IndexPage
